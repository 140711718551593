import lodashGet from 'lodash/get';
import produce from 'immer';
import { makeNoVideos } from "../types/api/video.model";
import colors from 'seagull/foundations/colors.module.scss';
import branding from "../infra/branding.module.scss";
import { generateUniqueId } from 'seagull/utils/generateUniqueId';
/** The location in featureFlags where we store the global feature-flags for users without a businessId. */
export const defaultFeatureFlagsKey = 'default';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const makeDefaultLoadableContent = () => ({
  loading: false,
  data: null,
  error: null,
  lastSuccessfulFetch: null
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const makeLoadableContentSuccess = (data, lastSuccessfulFetch) => ({
  loading: false,
  data,
  error: null,
  lastSuccessfulFetch: lastSuccessfulFetch !== null && lastSuccessfulFetch !== void 0 ? lastSuccessfulFetch : Date.now()
});
export function getDefaultSessionData() {
  return {
    mgmtHost: '',
    apiGatewayHost: '',
    apiHost: '',
    pdfHost: '',
    authDomain: '',
    enableAppcues: false,
    appcuesAccountId: '',
    useAuthorizationHeader: false,
    sessionTimeoutMS: 36000000
  };
}
export function getDefaultInitialState(session) {
  let additionalInfo = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    authFlowDone = false,
    featureFlags = {}
  } = additionalInfo;
  return {
    session,
    ff: {
      featureFlags
    },
    authToken: null,
    authFlowDone,
    claims: null,
    userInfo: null,
    videos: makeNoVideos(),
    userClientData: null,
    businessClientData: {},
    businessInfo: null,
    stripeLoadingFailed: false,
    inAppNotificationsDrawerOpen: false,
    activityLog: {},
    agreements: makeDefaultLoadableContent(),
    proposals: makeDefaultLoadableContent(),
    proposalTemplates: makeDefaultLoadableContent(),
    drafts: makeDefaultLoadableContent(),
    payouts: makeDefaultLoadableContent(),
    contacts: makeDefaultLoadableContent(),
    qboCustomers: makeDefaultLoadableContent(),
    qboItems: makeDefaultLoadableContent(),
    qboClasses: makeDefaultLoadableContent(),
    financialCents: {
      items: makeDefaultLoadableContent(),
      users: makeDefaultLoadableContent()
    },
    karbon: {
      items: makeDefaultLoadableContent(),
      users: makeDefaultLoadableContent()
    },
    clientHub: {
      items: makeDefaultLoadableContent(),
      users: makeDefaultLoadableContent()
    },
    integrations: makeDefaultLoadableContent(),
    inAppNotifications: makeDefaultLoadableContent(),
    showNotificationPimple: false,
    monthlyEarnings: makeDefaultLoadableContent(),
    historicAgreementStats: makeDefaultLoadableContent(),
    salesSummary: makeDefaultLoadableContent(),
    historicRevenueStats: makeDefaultLoadableContent(),
    payoutsSummary: makeDefaultLoadableContent(),
    invoicesDigest: makeDefaultLoadableContent(),
    serviceTemplates: makeDefaultLoadableContent(),
    legalTerms: makeDefaultLoadableContent(),
    personaKYBStatus: makeDefaultLoadableContent(),
    appConfig: {
      termsAndConditionsPDFSizeLimitInMegabytes: 50,
      qboPollingInterval: 5000,
      generateUniqueId
    }
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getOrCreateStoreLocation = (state, baseLocation) => {
  const asArray = Array.isArray(baseLocation) ? baseLocation : [baseLocation];
  const location = lodashGet(state, asArray);
  if (location) {
    return location;
  }

  // Create empty state if there is no location.
  const pathWithoutLastPart = asArray.slice(0, -1);
  const lastPathPart = asArray[asArray.length - 1];
  const parent = lodashGet(state, pathWithoutLastPart);
  parent[lastPathPart] = makeDefaultLoadableContent();
  return parent[lastPathPart];
};
export function makeDefaultBranding() {
  return {
    textStandard: colors.defaultTextStandard,
    textSecondary: colors.defaultTextSecondary,
    buttonPrimary: colors.defaultButtonPrimary,
    buttonSecondary: colors.defaultButtonSecondary,
    buttonDisabled: colors.defaultButtonDisabled,
    backgroundGradientStart: branding.defaultBgGradientColor1,
    backgroundGradientEnd: branding.defaultBgGradientColor2,
    backgroundStandard: colors.defaultBgStandard,
    backgroundLight: colors.defaultBgLight,
    progressBarComplete: colors.defaultStepperCompleted,
    progressBarIncomplete: colors.defaultStepperIncomplete
  };
}
export function initMakeFetchAndStoreFunction(_ref) {
  let {
    setState,
    getState,
    getNow
  } = _ref;
  return function makeFetchAndStoreFunction(_ref2) {
    let {
      fetcher,
      getLoadableContent,
      allowStaleDataFor
    } = _ref2;
    let promise = null;
    const fetchAndStore = async function fetchAndStore() {
      if (typeof fetcher !== 'function') {
        throw new Error('fetcherIsNotAFunction');
      }
      try {
        setState(produce(state => {
          const content = getLoadableContent(state);
          content.loading = true;
        }));
        if (!promise) {
          const content = getLoadableContent(getState());
          if (allowStaleDataFor != null && content.lastSuccessfulFetch != null && content.data && content.lastSuccessfulFetch + allowStaleDataFor > getNow().getTime() && !getState().appConfig.disableStaleDataCaching) {
            promise = Promise.resolve(content.data);
          } else {
            promise = fetcher(...arguments);
          }
        }
        const response = await promise;
        setState(produce(state => {
          const loc = getLoadableContent(state);
          loc.loading = false;
          loc.lastSuccessfulFetch = Date.now();
          loc.data = response;
          loc.error = null;
        }));
        return response;
      } catch (err) {
        setState(produce(state => {
          const loc = getLoadableContent(state);
          loc.loading = false;
          loc.data = null;
          loc.error = err;
        }));
        throw err;
      } finally {
        promise = null;
      }
    };
    return fetchAndStore;
  };
}