function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { format } from 'date-fns';
import { IntegrationResponseStatus } from "../types/api.model";
import { LatestOFACSanctionStatusModel, ProposalTemplateSummaryModel, SalesSummaryModel, TimeActivitiesModel } from "../models";
import { fromDatestampObject } from "../models/time";
import { ServiceInvoiceModel } from "../models/ServiceInvoiceModel";
import { AccountantModel, HistoricAgreementStatsModel, PayoutsSummaryModel, ReferralModel, integratedInvoiceModel, integrationModel, invoiceDraftModel, proposalTemplateModel, relationshipModel, relationshipSummaryModel, serviceModel, serviceTemplateModel, timeModel } from "../models";
import { selectors } from "./state-selectors";
import { draftModel } from "../models/draft";
import { invoiceModel, BillingControlDataModel, ActivityLogEntryModel } from "../models";
import { filtersConfig, FilterType } from "../pages/AgreementsDashboard/agreementsDashboardFilters";
import { utils } from "../utils";
import { ServiceStatusType } from "../models/service/model";
import { InvoiceQBOSyncStatusFilter } from 'anchor-scout/types';
import { APIHost } from "./sendRequest";
import { ServerError } from "../infra/ErrorResponse";
import { PayoutModel } from "../models/PayoutModel/PayoutModel";
import { ContactModel } from "../models/ContactModel";
import { MemberModel } from "../models";
import { InvoicesDigestModel } from "../models/invoices-digest/InvoicesDigestModel";
import { HistoricRevenueModel } from "../models/HistoricRevenueModel";
import { UserInfoModel } from "../models/UserInfoModel";
import { RecipientModel } from "../models/RecipientModel";
import { QboCustomerModel } from "../models/QboCustomerModel";
import { InvoiceDraftDatesModel } from "../models/InvoiceDraftDatesModel";
import { QboStatusFilter } from "../business-logic/qboStatusFilterLogic";
import { FutureInvoiceModel } from "../models/FutureInvoiceModel";
import { EmailOnBehalfSettingsModel } from "../models/EmailOnBehalfSettingsModel";
export function initStoreApi(_ref) {
  let {
    getState,
    getNow,
    sendRequest
  } = _ref;
  const mapResponseBody = res => res.data;
  return {
    charge: {
      approveAll(relationshipId) {
        return sendRequest({
          method: 'post',
          useMgmtHost: true,
          url: "/billing/".concat(relationshipId, "/charge/approve"),
          urlForSentry: '/billing/:id/charge/approve',
          data: {}
        }).then(mapResponseBody);
      },
      async approve(relationshipId, chargeId) {
        await sendRequest({
          method: 'post',
          useMgmtHost: true,
          url: "/billing/".concat(relationshipId, "/charge/").concat(chargeId, "/approve"),
          urlForSentry: '/billing/:id/charge/:id/approve'
        });
      },
      async delete(relationshipId, chargeId) {
        await sendRequest({
          method: 'post',
          useMgmtHost: true,
          url: "/billing/".concat(relationshipId, "/charge/").concat(chargeId, "/cancel"),
          urlForSentry: '/billing/:id/charge/:id/cancel',
          disableSentry: err => err.hasServerError(ServerError.AdHocChargeNotFound)
        });
      },
      async deleteCredit(relationshipId, chargeId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/billing/".concat(relationshipId, "/credit/").concat(chargeId, "/cancel"),
          urlForSentry: '/billing/:id/credit/:id/cancel'
        });
      },
      async pauseCredit(relationshipId, chargeId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/billing/".concat(relationshipId, "/credit/").concat(chargeId, "/pause"),
          urlForSentry: '/billing/:id/credit/:id/pause'
        });
      },
      async resumeCredit(relationshipId, chargeId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/billing/".concat(relationshipId, "/credit/").concat(chargeId, "/resume"),
          urlForSentry: '/billing/:id/credit/:id/resume'
        });
      }
    },
    service: {
      async togglePauseResumeBilling(relationshipId, serviceId, pause) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/service/").concat(serviceId, "/pause"),
          urlForSentry: '/relationship/:id/service/:id/pause',
          data: {
            pause
          }
        });
      },
      nextBillingDate(relationshipId, serviceId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/relationship/".concat(relationshipId, "/service/").concat(serviceId, "/next-billing-date"),
          urlForSentry: '/relationship/:id/service/:id/next-billing-date'
        }).then(res => timeModel.fromDatestampObject(res.data.date));
      }
    },
    billCommand: {
      async remove(relationshipId, serviceId, billCommandId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/service/").concat(serviceId, "/bill-command/").concat(billCommandId, "/cancel"),
          urlForSentry: '/relationship/:id/service/:id/bill-command/:id/cancel'
        });
      }
    },
    relationship: {
      getServiceNames(businessId, isVendor, type) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/business/".concat(businessId, "/relationships-summary-mv/service-names"),
          urlForSentry: '/business/:id/relationships-summary-mv/service-names',
          params: {
            party: isVendor ? 'vendor' : 'customer',
            status: type === 'agreement' ? "".concat(ServiceStatusType.Approved, ",").concat(ServiceStatusType.Terminated) : ServiceStatusType.AwaitingApproval
          }
        }).then(res => res.data.map(obj => obj.serviceName));
      },
      getSummaries(config) {
        const {
          businessId,
          type,
          isVendor,
          search,
          filters,
          clientContactId
        } = config;
        const filterParams = {};
        if (filters) {
          Object.entries(filtersConfig).forEach(_ref2 => {
            let [key, config] = _ref2;
            const value = filters[key];
            if (!value) return;
            if (key === FilterType.AgreementStatus && value.length > 0) {
              filterParams['agreementStatusV2'] = value.join(',');
            } else {
              Object.assign(filterParams, config.getRequestParam(value));
            }
          });
        }
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/business/".concat(businessId, "/relationships-summary-mv"),
          urlForSentry: '/business/:id/relationships-summary-mv',
          params: _objectSpread({
            party: isVendor ? 'vendor' : 'customer',
            // strings should be used from enum, but it's currently unclear which one to use. previously used one was ServiceStatusType which was not supposed to be related to this request
            status: type === 'agreement' ? "approved,terminated" : "awaitingApproval,expired",
            search: search || undefined,
            clientContactId: clientContactId || undefined
          }, filterParams)
        }).then(res => (res.data || []).map(relationshipSummaryModel.fromDTO));
      },
      /** Returns null if didn't connect any payment-method. */
      getSelectedPaymentMethod(relationshipId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/relationship/".concat(relationshipId, "/funding-source"),
          urlForSentry: '/relationship/:id/funding-source'
        }).then(res => res.data.details);
      },
      async changePaymentMethod(relationshipId, fundingSourceId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/funding-source/").concat(fundingSourceId),
          urlForSentry: '/relationship/:id/funding-source/:id',
          allowedForAccountants: true
        });
      },
      async disconnectPaymentMethod(relationshipId) {
        await sendRequest({
          method: 'post',
          useMgmtHost: true,
          url: "/relationship/".concat(relationshipId, "/disconnect-payment-method"),
          urlForSentry: '/relationship/:id/disconnect-payment-method'
        });
      },
      createFromDraft(draftId) {
        let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        return sendRequest({
          method: 'post',
          useMgmtHost: true,
          url: opts.externallySign ? '/relationship/approve/draft/vendor' : '/relationship/create-from-draft',
          data: {
            draftId,
            notifyPolicy: opts.sendSilently ? 'silent' : 'notify'
          },
          disableSentry: e => e.createRelationshipDisableSentry()
        }).then(mapResponseBody);
      },
      get(relationshipId) {
        let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        const {
          resolvePaymentMethod
        } = options;
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/relationship/".concat(relationshipId),
          urlForSentry: '/relationship/:id',
          params: {
            resolvePaymentMethod
          }
        }).then(res => relationshipModel.fromDTO(res.data));
      },
      getOriginalProposal(id) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/relationship/".concat(id, "/latest-proposal"),
          urlForSentry: '/relationship/:id/latest-proposal'
        }).then(mapResponseBody).then(relationshipModel.fromDTO);
      },
      /** Returns the new relationship version. */
      async update(relationshipId, body) {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/update"),
          urlForSentry: "/relationship/:id/update",
          data: body,
          disableSentry: err => err.hasServerError(ServerError.MismatchingRelationshipVersion)
        });
        return res.data;
      },
      async approve(relationshipData, additionalData) {
        const {
          id,
          version
        } = relationshipData;
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(id, "/approve/buyer"),
          urlForSentry: '/relationship/:id/approve/buyer',
          data: _objectSpread({
            version
          }, additionalData),
          disableSentry: err => err.hasServerError([ServerError.MismatchingRelationshipVersion, ServerError.InvalidRelationshipStatus, ServerError.UserAlreadySigned])
        });
      },
      async signatoryApprove(relationshipData, additionalData) {
        const {
          id,
          version
        } = relationshipData;
        const {
          fundingSourceId,
          requestAccountantAccessApproved
        } = additionalData;
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(id, "/approve/signatory"),
          urlForSentry: '/relationship/:id/approve/signatory',
          data: {
            version,
            fundingSourceId,
            requestAccountantAccessApproved
          },
          disableSentry: err => err.hasServerError([ServerError.MismatchingRelationshipVersion, ServerError.InvalidRelationshipStatus, ServerError.UserAlreadySigned])
        });
      },
      async terminateAgreement(relationshipId, notification) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/terminate"),
          urlForSentry: '/relationship/:id/terminate',
          data: notification ? {
            action: 'notify',
            notification
          } : {
            action: 'silent'
          },
          disableSentry: e => e.hasServerError(ServerError.RelationshipAlreadyTerminated)
        });
      },
      async requestPaymentMethod(relationshipId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/request-payment-method"),
          urlForSentry: '/relationship/:id/request-payment-method'
        });
      },
      getNextReminder(relationshipId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/relationship/".concat(relationshipId, "/next-reminder"),
          urlForSentry: '/relationship/:id/next-reminder'
        }).then(mapResponseBody);
      },
      async associateCustomer(relationshipId, customerId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/associate-customer"),
          urlForSentry: '/relationship/:id/associate-customer',
          data: {
            customerId
          }
        });
      },
      async stopAutoApproveAmendments(relationshipId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/stop-auto-approve-amendments"),
          urlForSentry: '/relationship/:id/stop-auto-approve-amendments'
        });
      },
      getSingleUseApprovalLink(relationshipId) {
        return sendRequest({
          useMgmtHost: true,
          disableAuth: true,
          method: 'get',
          url: "/relationship/".concat(relationshipId, "/single-use-approval-link"),
          urlForSentry: '/relationship/:id/single-use-approval-link',
          disableSentry: err => err.hasServerError(ServerError.RelationshipAlreadyApproved)
        }).then(mapResponseBody);
      },
      getProposalLinks(relationshipId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/relationship/".concat(relationshipId, "/proposal-links"),
          urlForSentry: '/relationship/:id/proposal-links',
          disableSentry: err => err.hasServerError(ServerError.RelationshipAlreadyApproved)
        }).then(res => {
          return {
            primary: RecipientModel.fromDTO(res.data.primary),
            additionalRecipients: res.data.additionalRecipients.map(RecipientModel.fromDTO)
          };
        });
      },
      getBillingControlData(relationshipId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/billing/".concat(relationshipId, "/control-data"),
          urlForSentry: '/billing/:id/control-data'
        }).then(res => BillingControlDataModel.fromDTO(res.data));
      },
      async sendProposalApprovalReminder(relationshipId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/reminder/proposal-approval"),
          urlForSentry: '/relationship/:id/reminder/proposal-approval'
        });
      },
      async cancelProposal(relationshipId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/cancel"),
          urlForSentry: '/relationship/:id/cancel',
          disableSentry: err => err.hasServerError(ServerError.InvalidRelationshipStatus)
        });
      },
      /** Will get a direct link to a relationship or an invoice, depending on the payload provided. */
      getDirectLink(relationshipId, data) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/direct-link"),
          urlForSentry: '/relationship/:id/direct-link',
          allowedForAccountants: true,
          data: {
            data: {
              [data.type]: data.id
            }
          }
        }).then(mapResponseBody);
      },
      async approveAllInvoices(relationshipId) {
        await sendRequest({
          method: 'post',
          useMgmtHost: true,
          url: "/relationship/".concat(relationshipId, "/approve-invoices"),
          urlForSentry: '/relationship/:id/approve-invoices',
          data: {}
        });
      },
      /** Updates the progressStatus of an agreement. */
      async updateProgressStatus(agreementId, progressStatus) {
        await sendRequest({
          method: 'post',
          useMgmtHost: true,
          url: "/relationship/".concat(agreementId, "/approval-progress"),
          urlForSentry: '/relationship/:id/approval-progress',
          data: {
            progressStatus
          },
          allowedForAccountants: true
        });
      },
      approveAllAmendments(relationshipId, relationshipVersion, amendmentVersion) {
        return sendRequest({
          method: 'post',
          useMgmtHost: true,
          url: "/relationship/".concat(relationshipId, "/approve-amendments"),
          urlForSentry: '/relationship/:id/approve-amendments',
          data: {
            version: relationshipVersion,
            amendmentVersion
          },
          disableSentry: err => err.hasServerError([ServerError.MismatchingRelationshipVersion, ServerError.InvalidRelationshipStatus])
        }).then(mapResponseBody);
      },
      submitCharges(relationshipId, data) {
        return sendRequest({
          method: 'post',
          useMgmtHost: true,
          url: "/billing/".concat(relationshipId, "/submit-charges"),
          urlForSentry: '/billing/:id/submit-charges',
          data
        }).then(mapResponseBody);
      },
      billCharges(relationshipId, data) {
        return sendRequest({
          method: 'post',
          useMgmtHost: true,
          url: "/billing/".concat(relationshipId, "/bill-charges"),
          urlForSentry: '/billing/:id/bill-charges',
          data
        }).then(mapResponseBody);
      },
      approveAmendmentFromEmail(_ref3) {
        let {
          approvalToken
        } = _ref3;
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/approve-amendments-by-token",
          data: {
            approvalToken
          },
          disableSentry: err => err.hasServerError([ServerError.MismatchingRelationshipVersion, ServerError.RelationshipAlreadyTerminated, ServerError.NoAmendmentsToApprove])
        }).then(mapResponseBody);
      }
    },
    draft: {
      getFullDrafts() {
        //TODO:ben add real implementation
        return Promise.resolve([]);
      },
      getSummaries() {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: '/relationships-drafts'
        }).then(mapResponseBody);
      },
      getById(draftId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/relationships-drafts/".concat(draftId),
          urlForSentry: '/relationships-drafts/:id'
        }).then(res => draftModel.fromDTO(res.data));
      },
      save(data) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/relationships-drafts/',
          data
        }).then(mapResponseBody);
      },
      async deleteById(draftId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationships-drafts/".concat(draftId, "/delete"),
          urlForSentry: '/relationships-drafts/:id/delete'
        });
      }
    },
    contact: {
      getAll() {
        return sendRequest({
          method: 'get',
          useMgmtHost: true,
          url: '/clients'
        }).then(mapResponseBody).then(contacts => contacts.map(c => new ContactModel(c)));
      },
      getById(contactId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/clients/".concat(contactId),
          urlForSentry: '/clients/:id'
        }).then(res => new ContactModel(res.data));
      },
      create(newContact) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/clients',
          data: _objectSpread(_objectSpread({}, newContact.toJSON()), {}, {
            isSample: false
          }),
          disableSentry: e => e.hasPhoneValidationError() || e.hasServerError([ServerError.DuplicateContact, ServerError.UserWithEmailAlreadyExists])
        }).then(res => new ContactModel(res.data));
      },
      async update(contact) {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/clients/".concat(contact.id(), "/update"),
          urlForSentry: '/clients/:id/update',
          data: {
            firstName: contact.firstName(),
            lastName: contact.lastName(),
            companyName: contact.companyName(),
            email: contact.email(),
            phone: contact.phone(),
            version: contact.version()
          },
          disableSentry: e => e.hasPhoneValidationError() || e.hasServerError([ServerError.DuplicateContact, ServerError.ContactVersionMismatch, ServerError.ContactNotEditable, ServerError.UserWithEmailAlreadyExists])
        });
        return new ContactModel(res.data);
      },
      async qboLink(contactId, data) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/clients/".concat(contactId, "/link"),
          urlForSentry: '/clients/:id/link',
          data
        });
      },
      async archive(contactId, data) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/clients/".concat(contactId, "/archive"),
          urlForSentry: '/clients/:id/archive',
          data,
          disableSentry: err => err.hasServerError([ServerError.FailedToArchiveContactDueToExistingRelationships, ServerError.ContactVersionMismatch])
        });
      },
      async restore(contactId, data) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/clients/".concat(contactId, "/restore"),
          urlForSentry: '/clients/:id/restore',
          data,
          disableSentry: err => err.hasServerError(ServerError.ContactVersionMismatch)
        });
      },
      qboCreate(contactId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/clients/".concat(contactId, "/qbo-create"),
          urlForSentry: '/clients/:id/qbo-create',
          disableSentry: err => err.hasServerError(ServerError.DuplicateNameExists)
        }).then(mapResponseBody);
      }
    },
    auth: {
      loginEmailStep(email, pageUrl) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/login/flow/".concat(email),
          urlForSentry: '/login/flow/:email',
          disableAuth: true,
          config: {
            headers: {
              'Anchor-Referrer': pageUrl
            }
          }
        }).then(mapResponseBody);
      },
      loginWithPassword(data) {
        const {
          email,
          password,
          utms,
          anchorReferrer
        } = data;
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/login',
          data: {
            email,
            password,
            utms
          },
          disableSentry: err => err.hasStatus([400]),
          config: {
            headers: {
              'Anchor-Referrer': anchorReferrer
            }
          }
        }).then(mapResponseBody);
      },
      async intuitSignIn(_ref4) {
        let {
          redirectUrl,
          authDomain,
          utms,
          ensureEmail
        } = _ref4;
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/qbo-integration/sign-in',
          data: ensureEmail ? {
            redirectUrl,
            authDomain,
            utms,
            ensureEmail
          } : {
            redirectUrl,
            authDomain,
            utms
          }
        });
        return res.data;
      },
      async signup(_ref5) {
        let {
          fullName,
          email,
          password,
          utms,
          pageUrl
        } = _ref5;
        const res = await sendRequest({
          method: 'post',
          useMgmtHost: true,
          url: '/signup',
          data: {
            fullName,
            email,
            password,
            utms
          },
          disableSentry: err => err.hasStatus([400, 409]),
          config: {
            headers: {
              'Anchor-Referrer': pageUrl
            }
          }
        });
        return res.data;
      },
      async approveTos(userId) {
        await sendRequest({
          method: 'post',
          useMgmtHost: true,
          url: "/user/".concat(userId, "/approve-tos"),
          urlForSentry: '/user/:id/approve-tos'
        });
      },
      async setPassword(password) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/user/password/set',
          data: {
            password
          },
          forceAuthorizationHeader: true
        });
      },
      getUserInfo() {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/user/me?expand=worksAt",
          disableSentry: err => err.hasStatus([401])
        }).then(res => res.data ? UserInfoModel.fromDTO(res.data) : null).catch(err => {
          var _err$response;
          if (((_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.status) === 401) {
            // This will happen when the user is removed from his team by another team-member.
            // Should trigger an automatic logout.
            return null;
          }
          throw err;
        });
      },
      getUserVideos() {
        return sendRequest({
          method: 'get',
          url: "/users/videos",
          apiHost: APIHost.Scout
        }).then(mapResponseBody);
      },
      editUserDetails(userDetails) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/user/me',
          allowedForAccountants: true,
          data: userDetails,
          disableSentry: err => err.hasStatus([400])
        }).then(mapResponseBody);
      },
      googleSignIn(_ref6) {
        let {
          pageUrl,
          idToken,
          utms,
          ensureEmail
        } = _ref6;
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/oauth',
          data: {
            provider: 'google',
            id_token: idToken,
            utms,
            ensureEmail
          },
          config: {
            headers: {
              'Anchor-Referrer': pageUrl
            }
          },
          disableSentry: err => err.hasStatus([403]) //an invalid email
        }).then(res => res.data.authToken);
      },
      async resetPassword(email) {
        await sendRequest({
          method: 'post',
          url: '/api/forgot-password/reset',
          data: {
            email
          },
          disableAuth: false,
          forceAuthorizationHeader: true
        });
      },
      async resendEmailVerification(email) {
        const {
          USE_EMAIL_VERIFICATION_TEMPLATE
        } = selectors.ff.featureFlagsData(getState());
        await sendRequest({
          useMgmtHost: USE_EMAIL_VERIFICATION_TEMPLATE ? true : undefined,
          method: 'post',
          url: USE_EMAIL_VERIFICATION_TEMPLATE ? '/resend-email-verification' : '/__/resend-email-verification',
          data: {
            recipient: email
          },
          disableAuth: true
        });
      },
      requestNewOTP(email) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/login/otp/send',
          data: {
            email
          }
        }).then(mapResponseBody);
      },
      validateOTP(email, otp, utms) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/login/otp',
          data: {
            email,
            otp,
            utms
          },
          disableSentry: err => err.hasStatus([400])
        }).then(res => res.data.authToken);
      }
    },
    video: {
      markVideoAsWatched(id) {
        return sendRequest({
          method: 'post',
          url: "/users/videos/".concat(id, "/watched"),
          apiHost: APIHost.Scout
        }).then(mapResponseBody);
      }
    },
    user: {
      switchBusiness(businessId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/user/me/switch-workspace',
          allowedForAccountants: true,
          data: {
            businessId
          }
        }).then(res => res.data.authToken);
      },
      createNewClientBusiness(businessName) {
        return sendRequest({
          method: 'post',
          useMgmtHost: true,
          url: '/business/customer',
          data: {
            name: businessName,
            accountsPayable: true,
            accountsReceivable: false
          }
        }).then(mapResponseBody);
      },
      getUserClientData() {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/client-data/user"
        }).then(mapResponseBody);
      },
      async setUserClientData(data) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/client-data/user",
          data
        });
      },
      getBusinessClientData() {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/client-data/business"
        }).then(mapResponseBody);
      },
      async setBusinessClientData(data) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/client-data/business",
          data
        });
      },
      referral() {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: '/user/referral',
          urlForSentry: '/user/referral'
        }).then(mapResponseBody).then(ReferralModel.fromDTO);
      },
      updateBranding(businessId, branding) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/".concat(businessId, "/branding"),
          urlForSentry: '/business/:id/branding',
          data: branding
        }).then(mapResponseBody);
      },
      changeUserEmail(_ref7) {
        let {
          email
        } = _ref7;
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/user/change-email/otp",
          data: {
            email
          },
          allowedForAccountants: true,
          disableSentry: err => err.hasServerError([ServerError.EmailAlreadyRegistered]) || err.isTooManyAttemptsError()
        }).then(mapResponseBody);
      },
      verifyEmailWithOTP(_ref8) {
        let {
          otp,
          email
        } = _ref8;
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/user/change-email/verify",
          allowedForAccountants: true,
          data: {
            email,
            otp
          },
          disableSentry: err => err.hasServerError([ServerError.EmailAlreadyRegistered, ServerError.InvalidOTP]) || err.isTooManyAttemptsError()
        }).then(mapResponseBody);
      },
      getOTPCode() {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/user/generic-otp/send",
          allowedForAccountants: true,
          disableSentry: err => err.isTooManyAttemptsError()
        }).then(mapResponseBody);
      },
      verifyOTP(_ref9) {
        let {
          otp
        } = _ref9;
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/user/generic-otp/verify",
          allowedForAccountants: true,
          data: {
            otp
          },
          disableSentry: err => err.hasServerError([ServerError.InvalidOTP]) || err.isTooManyAttemptsError()
        }).then(mapResponseBody);
      }
    },
    vendor: {
      async create(data) {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/business/vendor',
          data,
          disableSentry: err => err.isStripeError()
        });
        return res.data;
      },
      async update(data) {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/business/vendor/update',
          data,
          disableSentry: err => err.isStripeError()
        });
        return res.data;
      },
      async authorizeVendorBankAccount(_ref10) {
        let {
          routingNumber,
          accountNumber
        } = _ref10;
        await sendRequest({
          apiHost: APIHost.Plaid,
          method: 'post',
          url: '/direct/authorize-account',
          urlForSentry: '/direct/authorize-account',
          data: {
            routingNumber,
            accountNumber
          }
        });
      },
      async updateBusinessDetails(businessId, data) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/".concat(businessId, "/details"),
          urlForSentry: '/business/:id/details',
          data
        });
      },
      async updateBusinessSettings(businessId, data) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/".concat(businessId, "/settings"),
          urlForSentry: '/business/:id/settings',
          data
        });
      },
      getBusinessSettings(businessId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/business/".concat(businessId, "/settings"),
          urlForSentry: '/business/:id/settings'
        }).then(mapResponseBody);
      },
      getStripeHostedKYBUrl(businessId) {
        return sendRequest({
          apiHost: APIHost.KYB,
          method: 'get',
          url: "/business/".concat(businessId, "/stripe-hosted-kyb"),
          urlForSentry: '/business/:id/stripe-hosted-kyb'
        }).then(mapResponseBody);
      },
      getKYBInquiry(businessId) {
        return sendRequest({
          apiHost: APIHost.KYB,
          method: 'get',
          url: "/business/".concat(businessId, "/inquiry"),
          urlForSentry: '/business/:id/inquiry'
        }).then(mapResponseBody);
      },
      async getKYBStatus(businessId) {
        const res = await sendRequest({
          apiHost: APIHost.KYB,
          method: 'get',
          url: "/business/".concat(businessId, "/kyb-status"),
          urlForSentry: '/business/:id/kyb-status'
        });
        return res.data;
      },
      getTaxInfo(businessId) {
        return sendRequest({
          apiHost: APIHost.KYB,
          method: 'get',
          url: "/business/".concat(businessId, "/tax-info"),
          urlForSentry: '/business/:id/tax-info'
        }).then(mapResponseBody);
      }
    },
    ofacScreening: {
      async createInquiry(data) {
        const res = await sendRequest({
          apiHost: APIHost.KYB,
          method: 'post',
          url: '/ofac-screening-inquiry',
          data
        });
        return res.data;
      },
      async getUserStatus(userId) {
        const res = await sendRequest({
          apiHost: APIHost.KYB,
          method: 'get',
          url: "/user/".concat(userId, "/ofac-screening-status"),
          urlForSentry: '/user/:id/ofac-screening-status'
        });
        return new LatestOFACSanctionStatusModel(res.data);
      },
      async getBusinessStatus(businessId) {
        const res = await sendRequest({
          apiHost: APIHost.KYB,
          method: 'get',
          url: "/business/".concat(businessId, "/ofac-screening-status"),
          urlForSentry: '/business/:id/ofac-screening-status'
        });
        return new LatestOFACSanctionStatusModel(res.data);
      }
    },
    zapier: {
      getApiKey() {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/integrations/zapier/api-key',
          urlForSentry: '/integrations/zapier/api-key',
          allowedForAccountants: true
        }).then(res => res.data.apiKey);
      }
    },
    member: {
      async getAll(businessId) {
        const bid = businessId || selectors.user.businessId(getState());
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/business/".concat(bid, "/member"),
          urlForSentry: '/business/:id/member'
        });
        return {
          members: res.data.members.map(m => new MemberModel(m)),
          accountants: res.data.accountants.map(m => new AccountantModel(m))
        };
      },
      async invite(data) {
        const businessId = selectors.user.businessId(getState());
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/".concat(businessId, "/member/invite"),
          urlForSentry: '/business/:id/member/invite',
          data,
          disableSentry: err => err.hasServerError([ServerError.UserAlreadyInBusiness, ServerError.UserAlreadyAffiliatedWithBusiness, ServerError.PendingInvitationAlreadyExists])
        });
      },
      async resendInvitation(businessId, invitedUserId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/".concat(businessId, "/member/resend-invitation"),
          urlForSentry: '/business/:id/member/resend-invitation',
          data: {
            invitedUserId
          }
        });
      },
      async changeRole(data) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/member/change-role",
          data
        });
      },
      async remove(data) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/business/member/deactivate',
          data
        });
      },
      getAssignedRelationships(memberId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/relationship/count/user/".concat(memberId)
        }).then(res => res.data.count);
      },
      async reassignAllRelationships(data) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/relationship/reassign/all',
          data
        });
      }
    },
    activityLog: {
      async get(relationshipId) {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/relationship/".concat(relationshipId, "/activity-log"),
          urlForSentry: '/relationship/:id/activity-log'
        });
        return res.data.logs.map(ActivityLogEntryModel.make);
      }
    },
    invoice: {
      async void(invoiceId) {
        await sendRequest({
          method: 'post',
          useMgmtHost: true,
          url: "/invoice/".concat(invoiceId, "/void"),
          urlForSentry: '/invoice/:id/void'
        });
      },
      integrationDetails(invoiceId, isZeroAmount) {
        return sendRequest({
          method: 'get',
          useMgmtHost: true,
          url: "/invoice/".concat(invoiceId, "/integrated-invoice"),
          urlForSentry: '/invoice/:id/integrated-invoice'
        }).then(res => integratedInvoiceModel.fromDTO(res.data, isZeroAmount));
      },
      getByIDScout(invoiceId, isCustomer) {
        return sendRequest({
          apiHost: APIHost.Scout,
          method: 'get',
          url: "/invoice/".concat(invoiceId),
          urlForSentry: '/invoice/:id',
          params: {
            view: isCustomer ? 'customer' : 'vendor'
          }
        }).then(res => invoiceModel.fromDTO(!isCustomer, res.data));
      },
      downloadPDF(invoiceId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/invoice/".concat(invoiceId, "/pdf"),
          urlForSentry: '/invoice/:id/pdf'
        }).then(res => res.data.url);
      },
      async resend(invoiceId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/invoice/".concat(invoiceId, "/resend"),
          urlForSentry: '/invoice/:id/resend'
        });
      },
      async retryCharge(invoiceId) {
        await sendRequest({
          method: 'post',
          useMgmtHost: true,
          url: "/invoice/".concat(invoiceId, "/retry-payment"),
          urlForSentry: '/invoice/:id/retry-payment'
        });
      },
      async markAsPaidExternally(invoiceId) {
        await sendRequest({
          method: 'post',
          useMgmtHost: true,
          url: "/invoice/".concat(invoiceId, "/paid-externally"),
          urlForSentry: '/invoice/:id/paid-externally',
          disableSentry: err => err.hasServerError(ServerError.IncorrectStatusForPaidExternally)
        });
      },
      async approve(invoiceId) {
        const isCustomer = selectors.user.isClient(getState());
        if (!isCustomer) throw new Error('only clients can approve invoice');
        await sendRequest({
          method: 'post',
          useMgmtHost: true,
          url: "/invoice/".concat(invoiceId, "/approve"),
          urlForSentry: '/invoice/:id/approve',
          params: {
            isCustomer
          }
        });
      },
      async getSummariesScout() {
        let filters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        const {
          relationshipId,
          sort,
          search,
          status,
          paymentStatus,
          issuedOnSince,
          issuedOnUntil,
          integration,
          pageSize,
          pageNumber
        } = filters;
        const isVendor = selectors.user.isVendor(getState());
        const serializeArray = arr => arr && arr.length > 0 ? arr.join(',') : undefined;
        const serializeDate = date => date ? format(date, 'yyyy-MM-dd') : undefined;
        const res = await sendRequest({
          method: 'get',
          apiHost: APIHost.Scout,
          url: '/invoices-summary-with-counters',
          params: {
            view: isVendor ? 'vendor' : 'customer',
            relationship: relationshipId,
            sort: sort ? "".concat(sort.field, ",").concat(sort.type) : undefined,
            pageSize,
            pageNumber,
            search: search || undefined,
            status: serializeArray(status),
            paymentStatus: serializeArray(paymentStatus),
            issuedOnSince: serializeDate(issuedOnSince),
            issuedOnUntil: serializeDate(issuedOnUntil),
            integration: integration === QboStatusFilter.Synced ? InvoiceQBOSyncStatusFilter.Synced : filters.integration === QboStatusFilter.NotSynced ? InvoiceQBOSyncStatusFilter.Unsynced : undefined
          }
        });
        return {
          invoices: res.data.invoices.map(inv => invoiceModel.fromDTO(isVendor, inv)),
          total: res.data.total,
          statusFilterCounters: res.data.statusFilterCounters,
          paymentStatusFilterCounters: res.data.paymentStatusFilterCounters,
          qboStatusFilterCounters: res.data.qboStatusFilterCounters
        };
      },
      async exportToCsv() {
        let filters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        const {
          sort,
          search,
          status,
          paymentStatus,
          issuedOnSince,
          issuedOnUntil,
          integration
        } = filters;
        const serializeArray = arr => arr && arr.length > 0 ? arr.join(',') : undefined;
        const serializeDate = date => date ? format(date, 'yyyy-MM-dd') : undefined;
        const res = await sendRequest({
          method: 'get',
          apiHost: APIHost.Scout,
          url: '/invoices-csv',
          params: {
            sort: sort ? "".concat(sort.field, ",").concat(sort.type) : undefined,
            search: search || undefined,
            status: serializeArray(status),
            paymentStatus: serializeArray(paymentStatus),
            issuedOnSince: serializeDate(issuedOnSince),
            issuedOnUntil: serializeDate(issuedOnUntil),
            integration: integration === QboStatusFilter.Synced ? InvoiceQBOSyncStatusFilter.Synced : filters.integration === QboStatusFilter.NotSynced ? InvoiceQBOSyncStatusFilter.Unsynced : undefined
          }
        });
        return res.data;
      },
      async stopPayment(invoiceId, reason) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/invoice/".concat(invoiceId, "/stop-payment"),
          data: {
            reason
          }
        });
      },
      async resumePayment(invoiceId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/invoice/".concat(invoiceId, "/resume-payment")
        });
      },
      async refundPayment(invoiceId, reason) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/invoice/".concat(invoiceId, "/refund-payment"),
          data: {
            reason
          }
        });
      },
      async payNow(invoiceId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/invoice/".concat(invoiceId, "/pay-now")
        });
      },
      disableAutoApprove(invoiceID) {
        return sendRequest({
          useMgmtHost: true,
          urlForSentry: '/invoice/:id/auto-approve/disable',
          method: 'post',
          url: "/invoice/".concat(invoiceID, "/auto-approve/disable")
        }).then(mapResponseBody);
      },
      getDigested() {
        return sendRequest({
          apiHost: APIHost.Scout,
          method: 'get',
          url: "/invoices-digest"
        }).then(response => InvoicesDigestModel.fromDTO(response.data));
      },
      approveInvoiceFromEmail(_ref11) {
        let {
          approvalToken
        } = _ref11;
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/invoice/approve-by-token",
          data: {
            approvalToken
          },
          disableSentry: err => err.hasServerError([ServerError.InvoiceAlreadyApproved, ServerError.InvoiceWasVoided])
        }).then(mapResponseBody);
      }
    },
    serviceTemplate: {
      getAll(businessId, params) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/business/".concat(businessId, "/service-template"),
          urlForSentry: "/business/:id/service-template",
          params
        }).then(res => {
          const {
            services
          } = res.data;
          const raw = services.map(serviceTemplateModel.fromDTO);
          const active = [];
          const deleted = [];
          raw.forEach(s => {
            if (serviceTemplateModel.isDeleted(s)) {
              deleted.push(s);
            } else {
              active.push(s);
            }
          });
          return {
            active,
            deleted,
            raw
          };
        });
      },
      getById(businessId, serviceTemplateId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/business/".concat(businessId, "/service-template/").concat(serviceTemplateId),
          urlForSentry: '/business/:id/service-template/:serviceId'
        }).then(res => serviceTemplateModel.fromDTO(res.data));
      },
      create(serviceTemplate, businessId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/v2/business/".concat(businessId, "/service-template/create"),
          urlForSentry: 'v2/business/:id/service-template/create',
          data: serviceTemplateModel.toDTO(serviceTemplate),
          disableSentry: err => err.hasServerError(ServerError.ServiceTemplateAlreadyExists)
        }).then(mapResponseBody);
      },
      update(serviceTemplate, businessId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/v2/business/".concat(businessId, "/service-template/update"),
          urlForSentry: '/v2/business/:id/service-template/update',
          data: serviceTemplateModel.toDTO(serviceTemplate),
          disableSentry: err => err.hasServerError(ServerError.ServiceTemplateAlreadyExists)
        }).then(() => {});
      },
      async delete(id, businessId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/".concat(businessId, "/service-template/delete"),
          urlForSentry: "/business/:id/service-template/delete",
          data: {
            id
          }
        });
      }
    },
    integration: {
      async getAll() {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: '/integrations/list'
        });
        return res.data.map(integrationModel.fromDTO);
      }
    },
    qbo: {
      connect(redirectUrl) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/integrations',
          data: {
            source: 'direct-qbo',
            redirectUrl
          }
        }).then(mapResponseBody);
      },
      disconnect() {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/integrations/disconnect'
        }).then(mapResponseBody);
      },
      async updateSettings(settings) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/integrations/update",
          urlForSentry: '/integrations/:id/update',
          data: settings
        });
      },
      getAccounts() {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: '/integrations/accounts'
        }).then(mapResponseBody);
      },
      getCustomers() {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/integrations/customers"
        }).then(res => res.data.customers.map(QboCustomerModel.fromDTO));
      },
      getItems() {
        return sendRequest({
          method: 'get',
          useMgmtHost: true,
          url: "/integrations/items",
          urlForSentry: '/integrations/items'
        }).then(mapResponseBody);
      },
      async linkAccount(data) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/integrations/accounts/link',
          data
        });
      },
      async linkServiceTemplate(businessId, serviceTemplateId, integratedId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/".concat(businessId, "/service-template/link"),
          urlForSentry: '/business/:id/service-template/link',
          data: {
            integratedId,
            id: serviceTemplateId
          }
        });
      },
      async syncInvoice(invoiceId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/integrations/invoices/".concat(invoiceId),
          urlForSentry: '/integrations/invoices/:id'
        });
      },
      async retrySyncInvoice(invoiceId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/integrations/invoices/".concat(invoiceId, "/retry"),
          urlForSentry: '/integrations/invoices/:id/retry'
        });
      },
      async syncPayment(invoiceId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/integrations/invoices/".concat(invoiceId, "/payment"),
          urlForSentry: '/integrations/invoices/:id/payment'
        });
      },
      async syncPayout(payoutId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/integrations/transfers/".concat(payoutId),
          urlForSentry: '/integrations/transfers/:id'
        });
      },
      async getClasses() {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: '/integrations/classes'
        });
        return res.data.classes;
      },
      async getTimeActivities(relationshipId) {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/integrations/relationships/".concat(relationshipId, "/time-activities")
        });
        return TimeActivitiesModel.fromDTO(res.data.services);
      }
    },
    financialCents: {
      async connect(redirectUrl) {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/integrations/financial-cents',
          data: {
            type: 'oauth',
            oauth: {
              redirectUrl
            }
          }
        });
        return res.data.oauth.linkUrl;
      },
      async disconnect() {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/integrations/financial-cents/disconnect'
        });
      },
      async getItems() {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: '/integrations/financial-cents/items'
        });
        return res.data.status === IntegrationResponseStatus.Success ? res.data.items : [];
      },
      async linkServiceTemplate(serviceTemplateId, integratedIds) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/integrations/financial-cents/items/link',
          data: {
            id: serviceTemplateId,
            integratedIds
          }
        });
      },
      async getUsers() {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: '/integrations/financial-cents/users'
        });
        return res.data.status === IntegrationResponseStatus.Success ? res.data.users || [] : [];
      },
      async getRoles(serviceTemplateId) {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/integrations/financial-cents/".concat(serviceTemplateId, "/roles")
        });
        return res.data.status === IntegrationResponseStatus.Success ? res.data.roles || [] : [];
      }
    },
    serviceInvoices: {
      getByBusinessId(businessId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/serviceBilling/".concat(businessId),
          urlForSentry: '/serviceBilling/:businessId'
        }).then(res => (res.data.Entries || []).map(ServiceInvoiceModel.fromDTO));
      },
      getCredit() {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: '/business/payments-credit'
        }).then(res => utils.centsToDollars(res.data.amount));
      },
      getDownloadURL(filename) {
        const businessId = selectors.user.businessId(getState());
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/business/".concat(businessId, "/file/").concat(filename),
          urlForSentry: '/business/:id/file/:id'
        }).then(mapResponseBody);
      }
    },
    paymentMethod: {
      /**
       * Fetches the payment methods for a given agreement
       * When the vendor sends the request he will get an empty array.
       */
      clientGet(vendorId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/payment-method/vendor/".concat(vendorId),
          urlForSentry: '/payment-method/vendor/:id'
        }).then(res => res.data.paymentMethods);
      },
      /**
       * Fetches the payment methods for a given agreement
       * When the client sends the request he will get an empty array.
       */
      vendorGet(customerId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/payment-method/customer/".concat(customerId),
          urlForSentry: '/payment-method/customer/:id'
        }).then(res => res.data.paymentMethods);
      },
      clientAddToBusiness(paymentMethodId, vendorId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/payment-method/".concat(paymentMethodId, "/vendor/").concat(vendorId),
          urlForSentry: '/payment-method/:id/vendor/:id',
          allowedForAccountants: true
        }).then(res => res.data.details);
      },
      vendorAddToBusiness(paymentMethodId, customerId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/payment-method/".concat(paymentMethodId, "/customer/").concat(customerId),
          urlForSentry: '/payment-method/:id/customer/:id'
        }).then(res => res.data.details);
      },
      async delete(fundingSourceId, vendorId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/payment-method/funding-source/".concat(fundingSourceId, "/delete"),
          urlForSentry: '/payment-method/funding-source/delete',
          allowedForAccountants: true,
          data: {
            vendorId
          }
        });
      },
      async clientDelete(fundingSourceId, vendorId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/payment-method/funding-source/".concat(fundingSourceId, "/by-customer/delete"),
          urlForSentry: '/payment-method/funding-source/:id/by-customer/delete',
          allowedForAccountants: true,
          data: {
            vendorId
          }
        });
      },
      async vendorDelete(fundingSourceId, customerId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/payment-method/funding-source/".concat(fundingSourceId, "/by-vendor/delete"),
          urlForSentry: '/payment-method/funding-source/:id/by-vendor/delete',
          allowedForAccountants: true,
          data: {
            customerId
          }
        });
      },
      createSetupIntent(vendorId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/payment-method/".concat(vendorId, "/create-payment-intent"),
          urlForSentry: '/payment-method/:vendorId/create-payment-intent',
          allowedForAccountants: true
        }).then(mapResponseBody);
      },
      clientCreateSetupIntent(vendorId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/payment-method/vendor/".concat(vendorId, "/create-payment-intent"),
          urlForSentry: '/payment-method/vendor/:vendorId/create-payment-intent',
          allowedForAccountants: true
        }).then(mapResponseBody);
      },
      vendorCreateSetupIntent(customerId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/payment-method/customer/".concat(customerId, "/create-payment-intent"),
          urlForSentry: '/payment-method/customer/:customerId/create-payment-intent'
        }).then(mapResponseBody);
      }
    },
    business: {
      getLegalTerms() {
        const businessId = selectors.user.businessId(getState());
        if (!businessId) throw new Error('no business ID');
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/business/".concat(businessId, "/legal-terms"),
          urlForSentry: '/business/:id/legal-terms'
        }).then(mapResponseBody);
      },
      async removeLegalTerms(legalTermsId) {
        const businessId = selectors.user.businessId(getState());
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/".concat(businessId, "/legal-terms/delete"),
          urlForSentry: '/business/:id/legal-terms/delete',
          data: {
            id: legalTermsId
          }
        });
      },
      createLegalTerms(data) {
        const businessId = selectors.user.businessId(getState());
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/".concat(businessId, "/legal-terms/create"),
          urlForSentry: '/business/:id/legal-terms/create',
          data
        }).then(mapResponseBody);
      },
      getLegalTermsUploadURL() {
        const businessId = selectors.user.businessId(getState());
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/business/".concat(businessId, "/legal-terms/upload-url"),
          urlForSentry: '/business/:id/legal-terms/upload-url'
        }).then(mapResponseBody);
      },
      getLegalTermsDownloadURL(filename, businessId) {
        const actualBusinessId = businessId || selectors.user.businessId(getState());
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/business/".concat(actualBusinessId, "/legal-terms/download-url"),
          urlForSentry: '/business/:id/legal-terms/download-url',
          params: {
            filename
          }
        }).then(res => res.data.url);
      },
      getLogoUploadURL() {
        const businessId = selectors.user.businessId(getState());
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/business/".concat(businessId, "/logo/upload-url"),
          urlForSentry: '/business/:id/logo/upload-url'
        }).then(res => res.data.url);
      },
      getInfo(businessId) {
        return sendRequest({
          method: 'get',
          url: "/businesses/".concat(businessId),
          urlForSentry: '/businesses/:id',
          apiHost: APIHost.Scout
        }).then(mapResponseBody);
      }
    },
    invoiceDraft: {
      getDates(relationshipId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/billing/".concat(relationshipId, "/dates"),
          urlForSentry: '/billing/:id/dates'
        }).then(res => InvoiceDraftDatesModel.fromDTO(res.data));
      },
      getByDate(relationshipId, date) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/billing/".concat(relationshipId, "/invoice-draft"),
          urlForSentry: '/billing/:id/invoice-draft',
          params: {
            issueDate: timeModel.toDatestampArray(date).join(',')
          }
        }).then(res => res.data && invoiceDraftModel.fromDTO(res.data));
      },
      getForAmendedService(relationshipId, service) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/relationship/".concat(relationshipId, "/amended-item-draft"),
          urlForSentry: '/relationship/:id/amended-item-draft',
          data: serviceModel.toDTO(service)
        }).then(res => ({
          issueDate: fromDatestampObject(res.data.issueDate),
          lineItem: invoiceDraftModel.lineItemFromDTO(res.data.lineItem)
        }));
      }
    },
    notification: {
      async getWhileLoggedIn(notificationId, intent) {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'get',
          params: {
            intent
          },
          url: "/notification/auth/".concat(notificationId),
          urlForSentry: '/notification/auth/:id',
          disableSentry: err => err.status === 401 || err.hasServerError([ServerError.RecipientNotFound, ServerError.InvalidTeamInvitation])
        });
        return res.data;
      },
      async getWhileLoggedOut(notificationId, intent) {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'get',
          params: {
            intent
          },
          url: "/notification/no-auth/".concat(notificationId),
          urlForSentry: '/notification/no-auth/:id',
          disableSentry: err => err.hasStatus([401])
        });
        return res.data;
      },
      async getNotificationSettings(businessId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/business/".concat(businessId, "/settings/notifications"),
          urlForSentry: "/business/:businessId/settings/notifications"
        }).then(res => _objectSpread(_objectSpread({}, res.data), {}, {
          sendOnBehalfOf: EmailOnBehalfSettingsModel.fromDTO(res.data.sendOnBehalfOf)
        }));
      },
      async updateNotificationSettings(_ref12) {
        let {
          data,
          businessId,
          notificationName
        } = _ref12;
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/".concat(businessId, "/settings/notifications/").concat(notificationName),
          urlForSentry: "/business/:businessId/settings/notifications/:notificationType",
          data
        });
      },
      async connectEmailOnBehalf(businessId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/".concat(businessId, "/settings/notifications/send-on-behalf-of/authorize-gmail"),
          urlForSentry: "/business/:businessId/settings/notifications/send-on-behalf-of/authorize-gmail"
        }).then(response => response.data.url);
      },
      async completeGmailAuthorization(businessId, code, scope) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/".concat(businessId, "/settings/notifications/send-on-behalf-of/complete-gmail-authorization"),
          urlForSentry: "/business/:businessId/settings/notifications/send-on-behalf-of/complete-gmail-authorization",
          data: {
            authorizationCode: code,
            scope
          }
        });
      },
      async disconnectEmailOnBehalf(businessId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'delete',
          url: "/business/".concat(businessId, "/settings/notifications/send-on-behalf-of"),
          urlForSentry: "/business/:businessId/settings/notifications/send-on-behalf-of"
        });
      }
    },
    inAppNotifications: {
      getAll() {
        return sendRequest({
          method: 'get',
          useMgmtHost: true,
          url: '/business/notifications-mailbox'
        }).then(mapResponseBody);
      },
      async markAsSeen(notificationItemIds) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/notifications-mailbox/mark-as-seen",
          data: {
            ids: notificationItemIds
          }
        });
      },
      async markAsRead(notificationItemId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/notifications-mailbox/mark-as-read",
          data: {
            id: notificationItemId
          }
        });
      }
    },
    accountants: {
      /** Request access as an accountant to the customer's business. */
      async requestAccess(customerId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/accountants/request-access/".concat(customerId),
          urlForSentry: '/business/accountants/request-access/:id'
        }).then(mapResponseBody);
      },
      /** Cancels an active request for access. */
      async cancelRequestAccess(customerId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/accountants/cancel-request-access/".concat(customerId),
          urlForSentry: '/business/accountants/cancel-request-access/:id'
        });
      },
      /** Removes access for the existing user as an accountant to the customer's business. */
      async removeMe(customerId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/accountants/remove-me/".concat(customerId),
          urlForSentry: '/business/accountants/remove-me/:id'
        });
      },
      async removeVendor(vendorId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/accountants/remove/".concat(vendorId),
          urlForSentry: '/business/accountants/remove/:id'
        });
      },
      async approveVendor(vendorId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/accountants/approve/".concat(vendorId),
          urlForSentry: '/business/accountants/approve/:id'
        });
      },
      async rejectVendor(vendorId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/business/accountants/reject/".concat(vendorId),
          urlForSentry: '/business/accountants/reject/:id'
        });
      }
    },
    payout: {
      getById(payoutId) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/v2/business/payouts/".concat(payoutId),
          urlForSentry: '/v2/business/payouts/:id'
        }).then(res => PayoutModel.fromDTO(res.data));
      },
      async getAll() {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: '/business/payouts',
          urlForSentry: '/business/payouts'
        });
        return {
          invoiceHeaders: res.data.InvoiceHeaders,
          payouts: res.data.Payouts.map(payout => PayoutModel.fromDTO(payout))
        };
      },
      async getSummary() {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: '/business/payouts-summary',
          params: {
            limit: 5,
            withFuturePayouts: 'true'
          }
        });
        return PayoutsSummaryModel.fromDTO(res.data);
      }
    },
    report: {
      getMonthlyEarnings() {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: '/business/report/monthly-earnings'
        }).then(mapResponseBody);
      },
      async getHistoricAgreementStats(businessId) {
        const res = await sendRequest({
          apiHost: APIHost.Scout,
          method: 'get',
          url: "/businesses/".concat(businessId, "/historic-agreements-stats"),
          urlForSentry: '/businesses/:id/historic-agreements-stats'
        });
        return res.data.map(stats => new HistoricAgreementStatsModel(stats));
      },
      async getSalesSummary() {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: '/business/sales-summary'
        });
        return SalesSummaryModel.fromDTO(res.data, getNow());
      },
      async getHistoricPaidExternallyInvoices() {
        const res = await sendRequest({
          apiHost: APIHost.Scout,
          method: 'get',
          url: "/historic-paid-externally-invoices"
        });
        return res.data.map(HistoricRevenueModel.fromHistoricInvoiceDTO);
      },
      async getFutureInvoiceTotals() {
        const res = await sendRequest({
          apiHost: APIHost.Scout,
          method: 'get',
          url: "/future-invoices/totals"
        });
        return res.data.map(HistoricRevenueModel.fromHistoricInvoiceDTO);
      }
    },
    featureFlag: {
      getAll(businessId, authToken) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: '/feature-flags',
          params: {
            businessId
          },
          authToken
        }).then(mapResponseBody);
      }
    },
    plaid: {
      getLinkToken(mode) {
        let {
          fundingSourceId,
          relationshipId
        } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        return sendRequest({
          apiHost: APIHost.Plaid,
          method: 'post',
          url: '/link-token',
          allowedForAccountants: true,
          data: {
            mode,
            fundingSourceId: fundingSourceId || null,
            relationshipId
          }
        }).then(response => response.data.token);
      },
      exchangePublicToken(body) {
        return sendRequest({
          apiHost: APIHost.Plaid,
          method: 'post',
          url: '/exchange-public-token',
          allowedForAccountants: true,
          data: body,
          disableSentry: err => err.isPlaidError()
        }).then(mapResponseBody);
      },
      async verifyMicroDeposit(fundingSourceId) {
        await sendRequest({
          apiHost: APIHost.Plaid,
          method: 'post',
          url: "/verify/".concat(fundingSourceId),
          urlForSentry: '/verify/:fundingSourceId',
          allowedForAccountants: true
        });
      },
      async failedVerification(fundingSourceId) {
        await sendRequest({
          apiHost: APIHost.Plaid,
          method: 'post',
          url: "/failed-verification/".concat(fundingSourceId),
          urlForSentry: '/verify-failed/:fundingSourceId',
          allowedForAccountants: true
        });
      },
      isLoginRequired(fundingSourceId) {
        return sendRequest({
          apiHost: APIHost.Plaid,
          method: 'post',
          url: "/funding-source/".concat(fundingSourceId, "/is-login-required"),
          urlForSentry: '/funging-source/:fundingSourceId/is-login-required',
          allowedForAccountants: true
        }).then(res => res.data.isLoginRequired);
      },
      async loginRepaired(fundingSourceId) {
        await sendRequest({
          apiHost: APIHost.Plaid,
          method: 'post',
          url: "/funding-source/".concat(fundingSourceId, "/login-repaired"),
          urlForSentry: '/funging-source/:fundingSourceId/login-repaired',
          allowedForAccountants: true
        });
      }
    },
    proposalTemplates: {
      async getAll() {
        if (!selectors.user.hasBusiness(getState())) {
          // Server response is 403 if user has no business.
          return [];
        }
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/proposal-templates/all"
        }).then(mapResponseBody).then(templates => templates.map(ProposalTemplateSummaryModel.fromDTO));
      },
      async get(id) {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/proposal-templates/".concat(id),
          urlForSentry: '/proposal-templates/:id'
        }).then(mapResponseBody).then(proposalTemplateModel.fromDTO);
      },
      async save(id, template) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/proposal-templates/".concat(id),
          urlForSentry: '/proposal-templates/:id',
          data: template,
          disableSentry: err => err.hasServerError(ServerError.NameAlreadyExists)
        }).then(mapResponseBody);
      },
      async rename(id, title) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/proposal-templates/".concat(id, "/rename"),
          urlForSentry: '/proposal-templates/:id/rename',
          data: {
            title
          },
          disableSentry: err => err.hasServerError(ServerError.NameAlreadyExists)
        }).then(mapResponseBody);
      },
      async remove(id) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/proposal-templates/".concat(id, "/delete"),
          urlForSentry: '/proposal-templates/:id/delete'
        }).then(mapResponseBody);
      },
      async create(template) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/proposal-templates/create",
          urlForSentry: '/proposal-templates/create',
          data: template,
          disableSentry: err => err.hasServerError(ServerError.NameAlreadyExists)
        }).then(mapResponseBody);
      },
      async createDraft(id) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/proposal-templates/".concat(id, "/create-relationship-draft"),
          urlForSentry: '/proposal-templates/:id/create-relationship-draft'
        }).then(mapResponseBody);
      },
      async createFromDraft(id, title) {
        return sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/proposal-templates/create-from-draft/".concat(id),
          urlForSentry: '/proposal-templates/create-from-draft/:id',
          disableSentry: err => err.hasServerError(ServerError.NameAlreadyExists),
          data: {
            title
          }
        }).then(mapResponseBody);
      }
    },
    reports: {
      async revenueReportServices() {
        return sendRequest({
          method: 'get',
          url: "/revenue-report/services",
          apiHost: APIHost.Scout
        }).then(mapResponseBody).catch(() => []);
      },
      async revenueReportCustomers() {
        return sendRequest({
          method: 'get',
          url: "/revenue-report/customers",
          apiHost: APIHost.Scout
        }).then(mapResponseBody).catch(() => []);
      },
      async revenueReport(_ref13) {
        let {
          start,
          end,
          breakdowns,
          aggregate,
          services,
          customers,
          sort
        } = _ref13;
        const params = {
          start,
          end,
          breakdowns,
          aggregate,
          sort
        };
        const queryParams = Object.entries(params).filter(_ref14 => {
          let [, value] = _ref14;
          return !!value;
        }).map(_ref15 => {
          let [key, value] = _ref15;
          return "".concat(key, "=").concat(value);
        }).join('&');
        return sendRequest({
          method: 'post',
          url: "/revenue-report".concat(queryParams !== '' ? "?".concat(queryParams) : ''),
          apiHost: APIHost.Scout,
          data: {
            services: services.length > 0 ? services : undefined,
            customers: customers.length > 0 ? customers : undefined
          }
        }).then(mapResponseBody).catch(() => []);
      },
      async activeServicesReportServices() {
        return sendRequest({
          method: 'get',
          url: "/active-services-report/services",
          apiHost: APIHost.Scout
        }).then(mapResponseBody).catch(() => []);
      },
      async activeServicesReportCustomers() {
        return sendRequest({
          method: 'get',
          url: "/active-services-report/customers",
          apiHost: APIHost.Scout
        }).then(mapResponseBody).catch(() => []);
      },
      async activeServicesReportAgreements() {
        return sendRequest({
          method: 'get',
          url: "/active-services-report/agreements",
          apiHost: APIHost.Scout
        }).then(mapResponseBody).catch(() => []);
      },
      async activeServicesReportAssignees() {
        return sendRequest({
          method: 'get',
          url: "/active-services-report/assignees",
          apiHost: APIHost.Scout
        }).then(mapResponseBody).catch(() => []);
      },
      async activeServicesReport(_ref16) {
        let {
          businessId,
          params,
          services,
          customers,
          agreements,
          assignees
        } = _ref16;
        const queryParams = Object.entries(params).filter(_ref17 => {
          let [, value] = _ref17;
          return value !== undefined;
        }).map(_ref18 => {
          let [key, value] = _ref18;
          return Array.isArray(value) ? "".concat(key, "=").concat(value.join(',')) : "".concat(key, "=").concat(value);
        }).join('&');
        return sendRequest({
          method: 'post',
          url: "/businesses/".concat(businessId, "/active-services-report").concat(queryParams !== '' ? "?".concat(queryParams) : ''),
          urlForSentry: '/businesses/:businessId/active-services-report',
          apiHost: APIHost.Scout,
          data: {
            services: services.length > 0 ? services : undefined,
            customers: customers.length > 0 ? customers : undefined,
            agreements: agreements.length > 0 ? agreements : undefined,
            assignees: assignees.length > 0 ? assignees : undefined
          }
        }).then(mapResponseBody).catch(() => []);
      },
      async remainingCreditReportServices() {
        return sendRequest({
          method: 'get',
          url: "/remaining-credit-report/services",
          apiHost: APIHost.Scout
        }).then(mapResponseBody).catch(() => []);
      },
      async remainingCreditReportCustomers() {
        return sendRequest({
          method: 'get',
          url: "/remaining-credit-report/customers",
          apiHost: APIHost.Scout
        }).then(mapResponseBody).catch(() => []);
      },
      async remainingCreditReportAgreements() {
        return sendRequest({
          method: 'get',
          url: "/remaining-credit-report/agreements",
          apiHost: APIHost.Scout
        }).then(mapResponseBody).catch(() => []);
      },
      async remainingCreditReport(_ref19) {
        let {
          services,
          customers,
          agreements,
          sort
        } = _ref19;
        return sendRequest({
          method: 'post',
          url: "/remaining-credit-report",
          apiHost: APIHost.Scout,
          data: {
            services: services.length > 0 ? services : undefined,
            customers: customers.length > 0 ? customers : undefined,
            agreements: agreements.length > 0 ? customers : undefined,
            sort
          }
        }).then(mapResponseBody).catch(() => []);
      },
      async getFutureInvoicesReport(params) {
        const serializeArray = array => array && array.length > 0 ? array.join(',') : undefined;
        return sendRequest({
          method: 'get',
          url: '/future-invoices',
          urlForSentry: '/future-invoices',
          apiHost: APIHost.Scout,
          params: {
            companyName: serializeArray(params.companies),
            agreementName: serializeArray(params.agreements),
            requiresApproval: serializeArray(params.requiresApproval),
            issueDateFrom: params.issueDateFrom,
            issueDateTo: params.issueDateTo,
            dueDateFrom: params.dueDateFrom,
            dueDateTo: params.dueDateTo,
            sortField: params.sortField,
            sortDirection: params.sortDirection
          }
        }).then(res => res.data.map(FutureInvoiceModel.fromDTO));
      },
      async getFutureInvoicesCompanyNames() {
        return sendRequest({
          method: 'get',
          url: '/future-invoices/company-names',
          apiHost: APIHost.Scout
        }).then(mapResponseBody);
      },
      async getFutureInvoicesAgreementNames() {
        return sendRequest({
          method: 'get',
          url: '/future-invoices/agreement-names',
          apiHost: APIHost.Scout
        }).then(mapResponseBody);
      }
    },
    karbon: {
      async setAPIKey(apiKey) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/integrations/karbon',
          urlForSentry: '/integrations/karbon',
          data: {
            type: 'apiKey',
            apiKey: {
              key: apiKey
            }
          }
        });
      },
      async disconnect() {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/integrations/karbon/disconnect',
          urlForSentry: '/integrations/karbon/disconnect'
        });
      },
      async getItems() {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: '/integrations/karbon/items',
          urlForSentry: '/integrations/karbon/items'
        });
        return res.data.status === IntegrationResponseStatus.Success ? res.data.items : [];
      },
      async linkServiceTemplate(id, integratedIds) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/integrations/karbon/items/link',
          urlForSentry: '/integrations/karbon/items/link',
          data: {
            id,
            integratedIds
          }
        });
      },
      async getRoles(serviceTemplateId) {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/integrations/karbon/".concat(serviceTemplateId, "/roles"),
          urlForSentry: "/integrations/karbon/serviceTemplateId/roles"
        });
        return res.data.status === IntegrationResponseStatus.Success ? res.data.roles || [] : [];
      },
      async getUsers() {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: '/integrations/karbon/users',
          urlForSentry: '/integrations/karbon/users'
        });
        return res.data.status === IntegrationResponseStatus.Success ? res.data.users || [] : [];
      },
      async updateKarbonIntegration(defaultAssigneeId) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: "/integrations/karbon/update",
          urlForSentry: '/integrations/karbon/update',
          data: {
            source: 'karbon',
            karbon: {
              defaultAssigneeId
            }
          }
        });
      },
      async getIntegration() {
        return sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/integrations/karbon",
          urlForSentry: '/integrations/karbon'
        }).then(mapResponseBody);
      }
    },
    clientHub: {
      async setAPIKey(apiKey) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/integrations/client-hub',
          urlForSentry: '/integrations/client-hub',
          data: {
            type: 'apiKey',
            apiKey: {
              key: apiKey
            }
          }
        });
      },
      async disconnect() {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/integrations/client-hub/disconnect',
          urlForSentry: '/integrations/client-hub/disconnect'
        });
      },
      async getItems() {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: '/integrations/client-hub/items',
          urlForSentry: '/integrations/client-hub/items'
        });
        return res.data.status === IntegrationResponseStatus.Success ? res.data.items : [];
      },
      async linkServiceTemplate(id, integratedIds) {
        await sendRequest({
          useMgmtHost: true,
          method: 'post',
          url: '/integrations/client-hub/items/link',
          urlForSentry: '/integrations/client-hub/items/link',
          data: {
            id,
            integratedIds
          }
        });
      },
      async getRoles(serviceTemplateId) {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: "/integrations/client-hub/".concat(serviceTemplateId, "/roles"),
          urlForSentry: "/integrations/client-hub/serviceTemplateId/roles"
        });
        return res.data.status === IntegrationResponseStatus.Success ? res.data.roles || [] : [];
      },
      async getUsers() {
        const res = await sendRequest({
          useMgmtHost: true,
          method: 'get',
          url: '/integrations/client-hub/users',
          urlForSentry: '/integrations/client-hub/users'
        });
        return res.data.status === IntegrationResponseStatus.Success ? res.data.users || [] : [];
      }
    }
  };
}